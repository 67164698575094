import { gql } from '@apollo/client';

import { DISCOUNT_FRAGMENT } from './discount';
import { DEAL_TERMINATION_FRAGMENT } from './dealTermination';
import { LSP_TYPE_FRAGMENT } from './lspType';

export const DEAL_FRAGMENT = gql`
  fragment deal on Deal {
    id
    active
    amount
    max_budget
    communication_language_name
    content_quarterly
    text_reduction_percent
    currency
    link_building_budget
    diversification_links_count
    sponsored_articles_count
    type
    type_name
    lspType {
      ...lspType
    }
    basket
    name
    start_date
    orig_start_date
    import_id
    is_whitelabel
    status
    status_name
    termination_should_be_saved
    product
    actions_quarterly
    content_guidelines
    content_creation
    content_implementation

    discounts {
      ...discount
    }

    terminations {
      ...dealTermination
    }

    extra_data {
      ads_min_budget
      ads_max_budget
      ads_percent_profit
      ads_profit_amount
      ads_client_pays_budget
    }
    suspension_date
  }

  ${DEAL_TERMINATION_FRAGMENT}
  ${DISCOUNT_FRAGMENT}
  ${LSP_TYPE_FRAGMENT}
`;
