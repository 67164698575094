import { gql } from '@apollo/client';

export const EXTERNAL_REPORT_SETTINGS_FRAGMENT = gql`
  fragment externalReportSettings on DomainExternalReportSettings {
    branded_keywords
    company
    country
    created_at
    domain {
      id
    }
    excluded_keywords
    language
    report_emails
    report_languages
    send_email
    template
    updated_at
    google_ads_account_id
    google_ads_send_email
    google_ads_report_emails
    google_ads_report_languages
    ecommerce

    GAMGAService {
      id
      name
    }
    GAMGAServiceAudience {
      id
      name
    }
    GAMSearchConsoleSite {
      id
      website_url
    }
  }
`;
