import { gql } from '@apollo/client';

export const GROUPED_PHRASE_MONTHS_VIEW_FRAGMENT = gql`
  fragment groupedPhraseMonthsView on Phrase {
    id
    value
    start_position
    user_assigned_url
    location
    location_key
    deleted_at
    is_paused
    import_id
    last_position_url
    monthAvgPositions {
      position
      date
    }
    search {
      id
      count
    }
    group {
      id
      name
    }
    domain {
      id
    }
  }
`;
