export enum Paths {
  DASHBOARD = '/',
  TICKETS = '/tickets',
  CONTRACTS = '/contracts',
  USERS = '/users',
  PROCEDURES = '/procedures',
  HELP = '/help',
  COMPANIES = '/companies',
  CONTACTS = '/contacts',
  SETTINGS = '/settings',
  ADMINISTRATION = '/administration',
  NOTIFICATIONS = '/notifications',
  PROJECTS = '/projects',
  SET_NEW_PASSWORD = '/set-new-password',
  TEAMS = '/teams',
  DOMAINS = '/domains',
  DOMAINS_STATISTICS = '/domains-statistics',
  ROLES = '/roles',
  UPTIME_MONITOR = '/uptime-monitor',
  S4S_INSTANCES = '/s4s-instances',
  LINK_BUILDING = '/linkbuilding',
  TRANSLATIONS = '/translations',
  GA_MANAGER = '/ga-manager',
  CMS = '/cms',
  COPYWRITING = '/copywriting',
  LSP_TYPES = '/lsp-types',
}
