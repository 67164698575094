import { useIntl } from 'react-intl';

import { Paths } from '@/consts';

export const useBreadcrumbNameMap = () => {
  const intl = useIntl();

  return [
    {
      paths: [`${Paths.USERS}`],
      name: intl.formatMessage({ defaultMessage: 'Pracownicy' }),
    },
    {
      paths: [`${Paths.USERS}/add`],
      name: intl.formatMessage({ defaultMessage: 'Dodawanie pracownika' }),
    },
    {
      paths: [`${Paths.USERS}/:id/overview/settings`],
      name: intl.formatMessage({ defaultMessage: 'Pracownik' }),
      suffix: intl.formatMessage({ defaultMessage: 'Ustawienia' }),
      hasId: true,
    },
    {
      paths: [`${Paths.USERS}/:id/overview/permissions`],
      name: intl.formatMessage({ defaultMessage: 'Pracownik' }),
      suffix: intl.formatMessage({ defaultMessage: 'Uprawnienia' }),
      hasId: true,
    },
    {
      paths: [`${Paths.USERS}/:id/overview/info`],
      name: intl.formatMessage({ defaultMessage: 'Pracownik' }),
      suffix: intl.formatMessage({ defaultMessage: 'Informacje' }),
      hasId: true,
    },
    {
      paths: [`${Paths.TICKETS}`],
      name: intl.formatMessage({ defaultMessage: 'Tickety' }),
    },
    {
      paths: [`${Paths.COPYWRITING}`],
      name: intl.formatMessage({ defaultMessage: 'Copywriting' }),
    },
    {
      paths: [`${Paths.DOMAINS_STATISTICS}`],
      name: intl.formatMessage({ defaultMessage: 'Domeny - Statystyki' }),
    },
    {
      paths: [`${Paths.TICKETS}/add`],
      name: intl.formatMessage({ defaultMessage: 'Dodawanie ticketu' }),
    },
    {
      paths: [`${Paths.TICKETS}/:id/view`],
      name: intl.formatMessage({ defaultMessage: 'Ticket' }),
      hasId: true,
    },
    {
      paths: [`${Paths.SETTINGS}`],
      name: intl.formatMessage({ defaultMessage: 'Ustawienia' }),
    },
    {
      paths: [`${Paths.SETTINGS}${Paths.PROJECTS}`],
      name: intl.formatMessage({ defaultMessage: 'Tickety - Projekty' }),
    },
    {
      paths: [`${Paths.SETTINGS}${Paths.PROJECTS}/global`],
      name: intl.formatMessage({ defaultMessage: 'Globalne ustawienia ticketów' }),
    },
    {
      paths: [`${Paths.SETTINGS}${Paths.ROLES}`],
      name: intl.formatMessage({ defaultMessage: 'Role' }),
    },
    {
      paths: [`${Paths.SETTINGS}${Paths.TEAMS}`],
      name: intl.formatMessage({ defaultMessage: 'Lista zespołów' }),
    },
    {
      paths: [`${Paths.UPTIME_MONITOR}`],
      name: intl.formatMessage({ defaultMessage: 'Uptime' }),
    },
    {
      paths: [`${Paths.NOTIFICATIONS}`],
      name: intl.formatMessage({ defaultMessage: 'Powiadomienia' }),
    },
    {
      paths: [`${Paths.NOTIFICATIONS}/settings`],
      name: intl.formatMessage({ defaultMessage: 'Ustawienia powiadomień' }),
    },
    {
      paths: [`${Paths.TICKETS}/kanban`],
      name: intl.formatMessage({ defaultMessage: 'Kanban' }),
    },
    {
      paths: [`${Paths.TEAMS}`],
      name: intl.formatMessage({ defaultMessage: 'Zespoły' }),
    },
    {
      paths: [`${Paths.TEAMS}/:id/view`],
      name: intl.formatMessage({ defaultMessage: 'Zespół' }),
      suffix: intl.formatMessage({ defaultMessage: 'Widok' }),
      hasId: true,
    },
    {
      paths: [`${Paths.DOMAINS}`],
      name: intl.formatMessage({ defaultMessage: 'Domeny' }),
    },
    {
      paths: [`${Paths.DOMAINS}/:id`],
      name: intl.formatMessage({ defaultMessage: 'Domena' }),
      hasId: true,
    },
    {
      paths: [`${Paths.DOMAINS}/:id/info`],
      name: intl.formatMessage({ defaultMessage: 'Info' }),
    },
    {
      paths: [`${Paths.DOMAINS}/:id/tickets`],
      name: intl.formatMessage({ defaultMessage: 'Tickety' }),
    },
    {
      paths: [`${Paths.DOMAINS}/:id/reports`],
      name: intl.formatMessage({ defaultMessage: 'Raporty' }),
    },
    {
      paths: [`${Paths.DOMAINS}/:id/copywriting`],
      name: intl.formatMessage({ defaultMessage: 'Copywriting' }),
    },
    {
      paths: [`${Paths.DOMAINS}/:id/subpages`],
      name: intl.formatMessage({ defaultMessage: 'Podstrony' }),
    },
    {
      paths: [`${Paths.DOMAINS}/:id/monitoring`],
      name: intl.formatMessage({ defaultMessage: 'Monitoring' }),
    },
    {
      paths: [`${Paths.DOMAINS}/:id/uptime-monitor`],
      name: intl.formatMessage({ defaultMessage: 'Uptime' }),
    },
    {
      paths: [`${Paths.DOMAINS}/:id/linkbuilding`],
      name: intl.formatMessage({ defaultMessage: 'Linkowanie' }),
    },
    {
      paths: [`${Paths.DOMAINS}/:id/credentials`],
      name: intl.formatMessage({ defaultMessage: 'Dostępy' }),
    },
    {
      paths: [`${Paths.S4S_INSTANCES}`],
      name: intl.formatMessage({ defaultMessage: 'Stats4Seo' }),
    },
    {
      paths: [`${Paths.LINK_BUILDING}`],
      name: intl.formatMessage({ defaultMessage: 'Linkowanie' }),
    },
    {
      paths: [`${Paths.SETTINGS}${Paths.TRANSLATIONS}`],
      name: intl.formatMessage({ defaultMessage: 'Tłumaczenia' }),
    },
    {
      paths: [Paths.GA_MANAGER],
      name: intl.formatMessage({ defaultMessage: 'GA Manager' }),
    },
  ];
};
